<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <I18n code="user.edit.title" v-if="isEditing"></I18n>
            <I18n code="user.new.title" v-else></I18n>
          </h2>
          <section class="relative">
            <div
              style="width: 100%; height: 80vh"
              class="flex justify-center"
              v-if="findLoading"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <div>
              <div class="grid grid-cols-12 mt-5">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                    col-span-12
                  "
                >
                  {{ i18n('iam.fields.firstName') }}
                </h4>
                <div
                  class="
                    lg:col-span-4
                    col-span-12
                    lg:mt-0
                    mt-2
                    required
                    relative
                  "
                >
                  <input
                    id="crud-form-1"
                    type="text"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-black
                    "
                    v-model="model.firstName"
                    :placeholder="i18n('iam.placeholder.firstName')"
                    required
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                    col-span-12
                  "
                >
                  {{ i18n('iam.fields.lastName') }}
                </h4>
                <div
                  class="
                    lg:col-span-4
                    col-span-12
                    lg:mt-0
                    mt-2
                    required
                    relative
                  "
                >
                  <input
                    id="crud-form-1"
                    type="text"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-black
                    "
                    v-model="model.lastName"
                    :placeholder="i18n('iam.placeholder.lastName')"
                    required
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                    col-span-12
                  "
                >
                  {{ i18n('iam.fields.email') }}
                </h4>
                <div
                  class="
                    lg:col-span-4
                    col-span-12
                    lg:mt-0
                    mt-2
                    required
                    relative
                  "
                >
                  <input
                    id="crud-form-1"
                    type="text"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-black
                    "
                    v-model="model.email"
                    :placeholder="i18n('iam.placeholder.email')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                    col-span-12
                  "
                >
                  {{ i18n('iam.fields.phoneNumber') }}
                </h4>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  style="dir: ltr"
                >
                  <vue-tel-input
                    v-model="model.phoneNumber"
                    mode="international"
                    :inputOptions="isRTL ? optionsAr : options"
                  ></vue-tel-input>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                    col-span-12
                  "
                >
                  {{ i18n('iam.fields.maxMenus') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="crud-form-1"
                    type="text"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-black
                    "
                    v-model="model.maxNumberOfMenus"
                    :placeholder="i18n('iam.placeholder.maxMenus')"
                    required
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                    col-span-12
                  "
                >
                  {{ i18n('iam.fields.hostingURL') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="crud-form-1"
                    type="text"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-black
                    "
                    v-model="model.hostingURL"
                    :placeholder="i18n('iam.placeholder.hostingURL')"
                    required
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                    col-span-12
                  "
                >
                  {{ i18n('iam.fields.avatar') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <div class="lg:col-span-6 col-span-12">
                    <Dropzone
                      id="dropzoneSingleRef"
                      ref-key="dropzoneSingleRef"
                      :options="{
                        url: 'https://httpbin.org/post',
                        paramName: 'file',
                        addRemoveLinks: true,
                        thumbnailWidth: 120,
                        thumbnailHeight: 120,
                        thumbnailMethod: 'crop',
                        // maxFilesize: 0.5,
                        maxFiles: 1,
                        headers: { 'My-Awesome-Header': 'header value' }
                      }"
                      class="dropzone mt-6 dropzone-avatar"
                    >
                      <div class="text-lg font-medium">
                        {{ i18n('slider.fileUploaderHint') }}
                      </div>
                    </Dropzone>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5" v-if="!isEditing">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                    col-span-12
                  "
                >
                  {{ i18n('iam.fields.password') }}
                </h4>
                <div
                  class="
                    sm:col-span-6
                    lg:col-span-4
                    col-span-12
                    lg:mt-0
                    mt-2
                    required
                    relative
                  "
                >
                  <input
                    id="crud-form-1"
                    type="text"
                    disabled
                    class="form-control w-full dark:placeholder-black"
                    v-model="model.tempPassword"
                  />
                </div>
                <div
                  class="
                    items-center
                    flex
                    lg:col-span-2
                    col-span-6
                    mt-3
                    md:mt-0
                  "
                >
                  <ShuffleIcon
                    class="cursor-pointer mx-3"
                    @click="generatePassword"
                  />
                  <CopyIcon class="cursor-pointer" @click="copyPassword" />
                </div>
              </div>
              <!-- END -->
              <div class="flex justify-center items-center mt-10">
                <AppButton
                  type="button"
                  class="btn bg-theme-31 text-white cursor-pointer"
                  :class="!saveLoading ? 'w-24' : ''"
                  :disabled="saveLoading || findLoading"
                  :loading="saveLoading"
                  @click="doSubmit()"
                >
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <I18n code="common.loading"></I18n>
                    <LoadingIcon
                      icon="three-dots"
                      color="#FFFFFF"
                      style="margin: 0 4px"
                    />
                  </template>
                </AppButton>
                <button
                  type="button"
                  class="
                    btn
                    bg-theme-32
                    text-black
                    w-24
                    ml-3
                    mr-3
                    cursor-pointer
                  "
                  @click="doCancel"
                >
                  <I18n code="common.cancel"></I18n>
                </button>
              </div>
            </div>
          </section>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, provide } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Dropzone from 'dropzone'
import Message from '@/shared/message/toastify'
import { FormSchema } from '@/shared/form/form-schema'
import { UserModel } from '@/store/user/user-model'

const { fields } = UserModel
const formSchema = new FormSchema([
  fields.id,
  fields.email,
  fields.file,
  fields.firstName,
  fields.lastName,
  fields.hostingURL,
  fields.maxNumberOfMenus,
  fields.phoneNumber,
  fields.avatar,
  fields.lang
])

export default defineComponent({
  props: {
    id: {
      type: String,
      required: false
    }
  },
  setup() {
    const errorMessage = ref('')
    let uploadLoading = ref(false)
    const dropzoneSingleRef = ref()
    const filesToSave = ref([])
    provide('bind[dropzoneSingleRef]', (el) => {
      dropzoneSingleRef.value = el
    })
    const clearDropzone = () => {
      Dropzone.forElement('#dropzoneSingleRef').removeAllFiles(true)
      while (filesToSave.value.length > 0) {
        filesToSave.value.pop()
      }
    }
    const getDropZoneInstance = () => {
      return dropzoneSingleRef.value
    }
    const setupDropZone = () => {
      const elDropzoneSingleRef = getDropZoneInstance()
      elDropzoneSingleRef.dropzone.on('success', (file) => {
        filesToSave.value.push(file)
      })

      elDropzoneSingleRef.dropzone.on('removedfile', (file) => {
        filesToSave.value.pop()
      })

      elDropzoneSingleRef.dropzone.on('complete', () => {
        uploadLoading = false
      })

      elDropzoneSingleRef.dropzone.on('uploadprogress', () => {
        uploadLoading = true
      })
    }
    onMounted(() => {
      // setupDropZone()
    })
    return {
      setupDropZone,
      uploadLoading,
      filesToSave,
      getDropZoneInstance,
      clearDropzone,
      errorMessage
    }
  },
  data() {
    return {
      model: {
        // email: values.email,
        // tempPassword: values.tempPassword || '',
        firstName: ''
        // lastName: '',
        // phoneNumber: '',
        // countryCode: '',
        // avatar:'',
        // lang: '',
        // maxNumberOfMenus: '',
        // hostingURL: ''
      },
      rules: formSchema.rules(),
      options: { placeholder: 'Enter Your Phone Number' },
      optionsAr: { placeholder: 'ادخل رقم تليفونك' }
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'user/form/record',
      findLoading: 'user/form/findLoading',
      saveLoading: 'user/form/saveLoading'
    }),
    isEditing() {
      return !!this.id
    }
  },
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.users')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.users')
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id)
    } else {
      this.doNew()
    }

    this.model = formSchema.initialValues(this.record || {})
    if (!this.isEditing) this.generatePassword()
  },
  methods: {
    ...mapActions({
      doNew: 'user/form/doNew',
      doFind: 'user/form/doFind',
      doCreate: 'user/form/doAdd',
      doUpdate: 'user/form/doUpdate'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    generatePassword() {
      var generator = require('generate-password')
      this.model.tempPassword = generator.generate({
        length: 10,
        numbers: true
      })
    },
    copyPassword() {
      navigator.clipboard.writeText(this.model.password)
      Message.success(this.i18n('common.clipboard'))
    },
    isFormValid() {
      for (var key in this.model) {
        var value = this.model[key]
        for (var groupKey in value) {
          var value2 = value[groupKey]
          if (value2.length == 0) {
            this.errorMessage = 'notifications.create.popup.emptyFields'
            return false
          }
        }
      }
      if (!this.validEmail(this.model.email)) {
        this.errorMessage = 'Please enter a valid e-mail'
        return false
      }
      return true
    },
    validEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    },
    doCancel() {
      this.clearDropzone()
      this.$router.back()
    },
    doReset() {
      this.model = formSchema.initialValues(this.record)
      this.clearDropzone()
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }

      this.model.lang = this.language
      const { id, ...values } = formSchema.cast(this.model)
      if (this.isEditing) {
        await this.doUpdate({
          id,
          values
        })
      } else {
        // const data = {
        //   email: values.email,
        //   tempPassword: values.tempPassword || '',
        //   firstName: values.firstName || '',
        //   lastName: values.lastName || '',
        //   phoneNumber: values.phoneNumber || '',
        //   countryCode: values.countryCode || '',
        //   avatar: values.avatar || '',
        //   lang: values.lang || '',
        //   maxNumberOfMenus: +values.maxNumberOfMenus || '',
        //   hostingURL: values.hostingURL || ''
        // }
        // console.log('file', this.filesToSave[0])
        await this.doCreate(values)
        // await this.doCreate({
        //   values,
        //   file: this.filesToSave[0]
        // })
      }
    }
  }
})
</script>

<style>
.dropzone-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
}
</style>
